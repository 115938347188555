import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, provideRouter, withComponentInputBinding } from '@angular/router';
import { provideHttpClient, withInterceptors, HttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideClientHydration } from '@angular/platform-browser';
import { provideToastr } from 'ngx-toastr';

import { routes } from './app.routes';
import { config, configProvider } from './config/app';
import { HttpInterceptor } from './mware/interceptors/http.interceptor';
import './extensions';
import * as Sentry from '@sentry/angular';

function initializeApp(httpClient: HttpClient, document: Document) {
  return () => {
    // TODO: call API to get theme and load theme
    document.body.classList.add('default');
  };
}

const toastrConfig = {
  timeOut: 2000,
  positionClass: 'toast-bottom-center',
  maxOpened: 2,
  autoDismiss: true,
  preventDuplicates: true,
  countDuplicates: true,
  includeTitleDuplicates: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: configProvider, useValue: config },
    provideHttpClient(withInterceptors([HttpInterceptor])),
    provideRouter(routes, withComponentInputBinding()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [HttpClient, DOCUMENT],
    },
    provideClientHydration(),
    provideAnimations(), // required animations providers
    provideToastr(toastrConfig),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, // hide sentry crash report modal
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
